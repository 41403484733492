import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FaArrowRight } from "react-icons/fa"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const navLinks = [
    {
      label: "Competition",
      url: "/competition/",
    },
    {
      label: "Resources",
      url: "/resources/",
    },
    {
      label: "Results",
      url: "/results/",
    },
    {
      label: "Chapters",
      url: "/chapters/",
    },
    {
      label: "About",
      url: "/about/",
    },
  ];

  return (
    <nav className="bg-white shadow fixed inset-x-0 top-0 z-30">
	{/*
      <div className="bg-blue-400 flex items-center justify-center h-14 sm:h-10 w-full">
        <div className="max-w-7xl mx-auto flex flex-wrap items-center justify-center content-center">
          <span className="text-center pr-2 inline-block">
            We are recruiting USABB & ABB team members
          </span>
          <Link to="/applications" className="font-bold underline flex items-center content-center">Apply Now! <FaArrowRight /></Link>
        </div>
      </div>
	  */}
      <div className="max-w-7xl mx-auto px-4 lg:px-8">
        <div className="flex justify-between h-16">
          <Link
            to="/"
            className="flex-shrink-0 flex items-center text-xl font-bold"
          >
            <StaticImage
              className="w-10 h-10 mr-2"
              src="../images/logo.png"
              alt="Biology Bowl"
            />
            <h3 className="nav-title">{site.siteMetadata.title}</h3>
          </Link>
          <div className="flex justify-end">
            <div className="hidden lg:ml-8 lg:flex space-x-8">
              {navLinks.map((link) => (
                <Link
                  to={link.url}
                  key={link.url}
                  className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
                  activeClassName="inline-flex items-center px-1 pt-1 border-b-2 border-blue-500 hover:border-blue-500 text-sm font-medium leading-5 text-gray-900 hover:text-gray-900 focus:text-gray-900 focus:outline-none focus:border-blue-500 transition duration-150 ease-in-out"
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </div>
          <div className="-mr-2 flex items-center lg:hidden">
            {/* Mobile menu button */}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              aria-label="Main menu"
              aria-expanded="false"
              onClick={() => setIsOpen(!isOpen)}
            >
              {!isOpen && (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}

              {isOpen && (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <div className={(isOpen ? "block" : "hidden") + " lg:hidden"}>
        <div className="pt-2 pb-3 space-y-1">
          {navLinks.map((link) => (
            <Link
              to={link.url}
              key={link.url}
              getProps={({ isCurrent }) => ({
                className: !isCurrent
                  ? "block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
                  : "block pl-3 pr-4 py-2 border-l-4 border-blue-500 text-base font-medium text-blue-700 bg-blue-50 focus:outline-none focus:text-blue-800 focus:bg-blue-100 focus:border-blue-700 transition duration-150 ease-in-out",
              })}
            >
              {link.label}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Header;
